<template>
  <ion-list v-if="resources != null">
    <ion-list-header class="ion-margin-bottom">
      <h3>Exam Result</h3>
    </ion-list-header>
    <ion-list
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-item>
        <ion-label>
          {{ item.user.user_detail.name }}
          <br />
          <p>
            Class: {{ item.user.student.section_standard.standard.name }} -
            {{ item.user.student.section_standard.section.name }} Roll:
            {{ item.user.student.roll_no }}
          </p>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button @click="getResult(item)">
            <ion-icon
              :ios="cloudDownloadOutline"
              :md="cloudDownloadOutline"
              size="small"
            ></ion-icon>
             &nbsp; Pdf
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-list>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No standards found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/vue";

import { cloudDownloadOutline } from "ionicons/icons";
import base_url from "../../../../apis/base_url";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    exam_id: {
      type: String,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItem,
    IonButtons,
    IonButton,
    IonIcon,
  },
  data() {
    return {
      cloudDownloadOutline,
      base_url,
    };
  },
  computed: {
    token() {
      return this.$store.getters["auth/token"];
    },
  },
  methods: {
    getResult(item) {
      const user_id_id = item.user.id;
      const exam_id = this.exam_id;
      const url = `${this.base_url}api/v1/download-result-student?pat=${this.token}&exam_id=${exam_id}&user_id=${user_id_id}`;
      const new_download_button = document.createElement("a");
      new_download_button.href = url;
      // new_download_button.download = "admit_card.pdf";
      new_download_button.click();
    },
  },
};
</script>